<template>
  <div class="inline-block text-left h-auto">
    <div
      ref="menu"
      class="flex items-center h-full z-20 cursor-pointer"
      @click="toggle"
    >
      <slot :is_open="isOpen" />
    </div>
    <div class="z-10" ref="items">
      <transition
        enter-active-class="transition duration-100 ease-out"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        enter-to-class="transform scale-100 opacity-100"
        enter-from-class="transform scale-95 opacity-0"
        leave-to-class="transform scale-95 opacity-0"
      >
        <div
          v-show="isOpen"
          class="
            absolute
            shadow-md
            rounded-md
            bg-white
            focus:outline-none
            divide-y divide-gray-100
            ring-1 ring-black ring-opacity-5
          "
          :class="[
            margin,
            width,
            classMenuItem,
            {
              '-right-0 origin-top-right': position == 'right',
              '-left-0  origin-top-left': position == 'left',
            },
            position == 'right' && showArrow ? '-right-' + offsetArrow : null,
            position == 'left' && showArrow ? '-left-' + offsetArrow : null,
          ]"
          v-if="hasSlot('menu-header') || hasSlot('menu-items')"
        >
          <div v-if="showArrow" class="relative">
            <div :class="['arrow-' + position]" />
          </div>

          <div class="p-3" v-if="hasSlot('menu-header')">
            <slot name="menu-header" />
          </div>
          <div v-if="hasSlot('menu-items')">
            <slot name="menu-items" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      isOpen: false,
      popperInstance: null,
    };
  },
  props: {
    width: {
      type: String,
      default: "w-56",
    },
    classMenuItem: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "right",
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    offsetArrow: {
      type: String,
      default: "3.5",
    },
    margin: {
      type: String,
      default: "mt-4",
    },
  },
  methods: {
    close(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    toggle(e) {
      this.isOpen = !this.isOpen;
      this.popperInstance.update();
    },
    hasSlot(name = "default") {
      return !!this.$slots[name];
    },
  },
  mounted() {
    document.addEventListener("click", this.close);

    this.popperInstance = createPopper(this.$refs.menu, this.$refs.items, {
      placement: this.position == "right" ? "bottom-end" : "bottom-start",
    });
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);

    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  },
};
</script>


<style scoped>
.arrow-right:before {
  content: "";
  display: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e6e6e7;
  float: right;
  margin-right: 14px;
  margin-top: -10px;
}

.arrow-right:after {
  content: "";
  display: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  float: right;
  margin-right: 14px;
  margin-top: -8px;
}

.arrow-left:before {
  content: "";
  display: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e6e6e7;
  float: left;
  margin-left: 14px;
  margin-top: -10px;
}

.arrow-left:after {
  content: "";
  display: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  float: left;
  margin-left: 14px;
  margin-top: -8px;
}
</style>

