<template>
  <app-full-modal :title="$t('payment.detail.title_modal')">
    <template v-slot:body>
      <div v-if="loading">
        <app-icon-loading iconColor="primary" class="mx-auto mt-10" />
      </div>

      <div v-else-if="payment_link">
        <div v-show="showBusinessDetails" class="mt-5">
          <p class="px-5 font-medium">
            {{ $t("business.main.details") }}
          </p>
          <div class="p-5 space-y-2">
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("business.table.header_businss_name") }}
              </p>
              <p class="col-span-3">{{ payment_link.business?.name }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("business.detail.company_name") }}
              </p>
              <p class="col-span-3">
                {{ payment_link.business?.detail?.company_name }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("business.detail.company_reg_no") }}
              </p>
              <p class="col-span-3">
                {{ payment_link.business?.detail?.company_reg_no }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("business.detail.company_location") }}
              </p>
              <p class="col-span-3">
                {{ payment_link.business?.city }} ,
                {{ payment_link.business?.state?.name }} ,
                {{ payment_link.business?.state?.country?.name }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("support.contact_no") }}
              </p>
              <p class="col-span-3">
                {{ payment_link.business?.detail?.phone_no ?? "-" }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-2 text-gray-400" width="120px">
                {{ $t("business.support_detail.support_email") }}
              </p>
              <p class="col-span-3">
                {{ payment_link.business?.support_detail?.support_email ?? "-" }}
              </p>
            </div>
          </div>
        </div>
        <app-modal-webhook-detail :show="showModalDetail" @close="showModalDetail = false"
          :businessId="payment_link.business_id" :webhookEventLog="selectWebhookEventLog" />
        <div class="space-y-8 p-5">
          <!-- Payment Header -->
          <div>
            <p class="text-gray-400 text-xs">
              {{ $t("payment.detail.payment_title") }}
            </p>
            <div class="flex flex-wrap justify-between">
              <div class="flex space-x-3 place-items-center">
                <p class="text-lg font-bold text-gray-700">
                  {{
                      $formats.currency(
                        payment_link.currency,
                        payment_link.amount ?? 0.0
                      )
                  }}
                </p>
                <app-badge class="flex space-x-1 text-xs" :status="payment_link.paid ? 'success' : 'light'">
                  <p>
                    {{
                        payment_link.paid
                          ? $t("payment.detail.paid")
                          : $t("payment.detail.unpaid")
                    }}
                  </p>
                  <app-icon v-if="payment_link.paid" name="CheckIcon" class="w-4" />
                </app-badge>
              </div>
              <div class="space-x-3 place-items-center py-2">
                <button @click="fetchData(payment_link._id)" v-if="false">
                  <div class="
                      px-2
                      py-1
                      border
                      rounded-md
                      bg-background
                      flex flex-row
                      justify-center
                      place-items-center
                      hover:bg-gray-100
                    ">
                    <app-icon name="RefreshIcon" class="h-4 w-4 text-gray-400 mt-0.5" />
                    <p class="ml-1 text-xs">
                      {{ $t("payment.detail.refresh") }}
                    </p>
                  </div>
                </button>
                <app-button-copy :title="$t('payment.detail.copy_link')" :textToCopy="payment_link.payment_url" />
                <app-button-outline class="bg-white" padding="px-2 py-1" v-show="resendEmailButton"
                  :disabled="payment_link.paid" @click.stop="resendEmail(payment_link)">

                  <app-icon-outline name="PaperAirplaneIcon" class="h-5 w-5 text-gray-400" />

                  <p class="ml-1 text-xs">
                    {{ $t("payment.detail.resend_email") }}
                  </p>
                </app-button-outline>
              </div>
            </div>
          </div>

          <!-- Payment Detail -->
          <div class="flex space-x-6 text-xs justify-between" v-for="payment in payment_link?.payments"
            :key="payment.attempt_id">
            <div :set="(attempt = getPaymentAttempt(payment.attempt_id))"></div>
            <div class="space-y-4">
              <p class="text-gray-400">
                {{ $t("payment.detail.date") }}
              </p>
              <p>
                {{
                    $moment(
                      Number(payment.payment_time?.$date?.$numberLong)
                    ).format("DD MMM YYYY hh:mm a")
                }}
              </p>
            </div>
            <div class="bg-divider-gray w-0.5"></div>
            <div class="space-y-3">
              <p class="text-gray-400">
                {{ $t("payment.detail.method") }}
              </p>
              <div class="flex space-x-2 place-items-center">
                <p>{{ attempt.payment_method?.name ?? "-" }}</p>
                <img class="h-5" :src="attempt.payment_method?.image_url" />
              </div>
            </div>
            <div class="bg-divider-gray w-0.5"></div>
            <div class="space-y-4">
              <p class="text-gray-400">
                {{ $t("payment.detail.email_address") }}
              </p>
              <p>{{ payment_link.email }}</p>
            </div>
            <div></div>
          </div>

          <!-- Attemp -->

          <div>
            <div class="space-y-2" v-for="(attempt, index) in reverseAttempt()" :key="attempt._id">
              <!-- Attemp Info -->

              <!-- Attemp History -->

              <div>
                <p class="text-gray-400 text-xs" v-if="index == 0">
                  {{ $t("payment.detail.attempt_history") }}
                </p>
              </div>

              <!-- Payment History -->
              <div>
                <div class="flex space-x-2 place-items-center">
                  <app-payment-detail-attempt-status :status="attempt.status" />

                  <p class="text-sm">
                    {{ $t("payment.link.attempt_status." + attempt.status) }}
                    ({{ attempt._id }})
                  </p>
                  <span v-if="attempt.status == 'paid' && false" class="flex place-items-center space-x-2">
                    <button>
                      <div class="
                          px-2
                          py-1
                          border
                          rounded-md
                          flex flex-row
                          justify-center
                          bg-background
                          place-items-center
                          hover:bg-gray-100
                          space-x-1
                        ">
                        <p class="text-xs">
                          {{ $t("payment.detail.refund") }}
                        </p>
                        <app-icon-outline name="ReceiptRefundIcon" class="h-4 w-4 text-gray-700" />
                      </div>
                    </button>
                  </span>
                  <span v-if="attempt.status !== 'paid'" class="flex place-items-center space-x-2">
                    <button @click.stop="requery(attempt._id)">
                      <div class="
                          px-2
                          py-1
                          border
                          rounded-md
                          flex flex-row
                          justify-center
                          bg-background
                          place-items-center
                          hover:bg-gray-100
                          space-x-1
                        ">
                        <app-icon-outline name="SwitchVerticalIcon" class="h-4 w-4 text-gray-700" />
                        <p class="text-xs">
                          {{ $t("payment.detail.requery") }}
                        </p>
                      </div>
                    </button>
                  </span>
                </div>
                <div class="flex flex-col mt-2 ml-3.5 border-l-2 border-dotted">
                  <div class="ml-6 pb-4 space-y-1">
                    <p class="text-sm text-gray-500">
                      {{ attempt.payment_method?.name ?? "-" }}
                    </p>
                    <p class="text-xs text-gray-400">
                      {{
                          $moment(
                            Number(attempt.created_at?.$date?.$numberLong)
                          ).format("DD MMM YYYY hh:mm a")
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="flex space-x-2 place-items-center">
                <span class="flex place-items-center space-x-1 p-1 filter grayscale">
                  <app-icon-small-logo class="mb-1.5" />
                </span>
                <p class="text-sm">{{ $t("payment.detail.created") }}</p>
              </div>
              <div class="flex flex-col ml-4">
                <div class="ml-6 pb-2">
                  <p class="text-xs text-gray-400">
                    {{
                        $moment(payment_link.created_at).format(
                          "DD MMM YYYY hh:mm a"
                        )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Payment Details -->

        <div>
          <p class="px-5 py-2 font-medium">
            {{ $t("payment.detail.payment_details") }}
          </p>
          <hr />
          <div class="p-5 space-y-2">
            <div v-if="this.isMasterBusinessRole" class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.business_name") }}
              </p>
              <p class="col-span-4">{{ payment_link.business.name }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.id") }}
              </p>
              <p class="col-span-4">{{ payment_link._id }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.title") }}
              </p>
              <p class="col-span-4">{{ payment_link.title }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.amount") }}
              </p>
              <p class="col-span-4">
                {{
                    $formats.currency(
                      payment_link.currency,
                      payment_link.amount ?? 0.0
                    )
                }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.charge_fees") }}
              </p>
              <p class="col-span-4">
                {{
                    $formats.currency(
                      payment_link.currency,
                      getPaymentAmount(payment_link?.payments)?.charges
                    ) ?? "-"
                }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.net_amount") }}
              </p>
              <p class="col-span-4">
                {{
                    $formats.currency(
                      payment_link.currency,
                      getPaymentAmount(payment_link?.payments)?.net_amount
                    ) ?? "-"
                }}
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.description") }}
              </p>
              <p class="col-span-4">
                <app-text-readmore :text="payment_link.description ?? '-'" :showReadMore="false" />
              </p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.ref_1") }}
              </p>
              <p class="col-span-4">{{ payment_link.reference ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.ref_2") }}
              </p>
              <p class="col-span-4">{{ payment_link.reference_2 ?? "-" }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.email_address") }}
              </p>
              <p class="col-span-4">{{ payment_link?.email }}</p>
            </div>
            <div class="block sm:grid grid-cols-5 text-sm">
              <p class="col-span-1 text-gray-400" width="120px">
                {{ $t("payment.detail.phone_no") }}
              </p>
              <p class="col-span-4">{{ payment_link?.phone_no }}</p>
            </div>
          </div>

          <!-- Processed Transaction -->
          <div v-if="payment_link.settlement != null">
            <p class="px-5 py-2 font-medium">
              {{ $t("payment.detail.payment_wallet") }}
            </p>
            <hr />
            <div class="p-5 space-y-2">
              <div class="block sm:grid grid-cols-5 text-sm">
                <p class="col-span-1 text-gray-400" width="120px">
                  {{ $t("payment.detail.id") }}
                </p>
                <p class="col-span-4">
                  {{ payment_link.settlement.wallet._id }}
                </p>
              </div>
              <div class="block sm:grid grid-cols-5 text-sm">
                <p class="col-span-1 text-gray-400" width="120px">
                  {{ $t("wallet.transaction.type") }}
                </p>
                <p class="capitalize">
                  {{ payment_link.settlement.wallet.type?.replace("_", " ") }}
                </p>
              </div>
              <div class="block sm:grid grid-cols-5 text-sm">
                <p class="col-span-1 text-gray-400" width="120px">
                  {{ $t("wallet.transaction.amount") }}
                </p>
                <p class="col-span-4">
                  {{
                      $formats.currency(
                        payment_link.settlement.wallet.currency,
                        payment_link.settlement.wallet.amount ?? 0.0
                      )
                  }}
                </p>
              </div>
              <div class="block sm:grid grid-cols-5 text-sm">
                <p class="col-span-1 text-gray-400" width="120px">
                  {{ $t("wallet.transaction.date") }}
                </p>
                <p class="col-span-4">
                  {{
                      $moment(payment_link.settlement.wallet.created_at).format(
                        "DD MMM YYYY hh:mm a"
                      )
                  }}
                </p>
              </div>
            </div>
          </div>

          <!-- Webhook Logs -->
          <div class="space-y-3">
            <app-expand :expanded="true">
              <template v-slot:head="slot">
                <div class="flex justify-between pb-3 px-5 py-2 cursor-pointer">
                  <p class="font-medium">
                    {{ $t("integration.webhook_event.webhook_event_logs") }}
                  </p>
                  <app-icon name="ChevronRightIcon" :class="[
                    'h-5 w-5 text-gray-500 transform',
                    slot.expanded ? '-rotate-90' : 'rotate-90',
                  ]" />
                </div>
              </template>
              <template v-slot:body>
                <div v-if="(payment_link.webhook_event_logs?.length ?? 0) == 0">
                  <p class="text-center p-3">
                    {{ $t("integration.webhook_event.no_webhook_logs_found") }}
                  </p>
                </div>
                <div v-for="webhook_event_log in payment_link.webhook_event_logs" :key="webhook_event_log"
                  class="space-y-5">
                  <div class="flex flex-col mt-2 border-t border-dotted py-3">
                    <div class="p-2 text-gray-700 text-sm px-5 space-y-2">
                      <div class="block sm:grid grid-cols-5 text-sm">
                        <p class="col-span-1" width="120px">
                          {{ $t("integration.webhook_event.status") }}:
                        </p>
                        <p class="col-span-4 mb-1">
                          <app-badge :status="
                            webhook_event_log.status == 'succeed'
                              ? 'success'
                              : 'light'
                          " class="w-16 text-xs">
                            {{ webhook_event_log.status }}
                          </app-badge>
                        </p>
                      </div>
                      <div class="block sm:grid grid-cols-5 text-sm">
                        <p class="col-span-1" width="120px">
                          {{ $t("integration.webhook_event.uuid") }}:
                        </p>
                        <p class="
                            col-span-4
                            underline
                            text-bluelink
                            cursor-pointer
                          " @click="onItemClick(webhook_event_log)">
                          {{ webhook_event_log.uuid }}
                        </p>
                      </div>
                      <div class="block sm:grid grid-cols-5 text-sm">
                        <p class="col-span-1" width="120px">
                          {{ $t("integration.webhook_event.url") }}:
                        </p>
                        <p class="col-span-4">{{ webhook_event_log.url }}</p>
                      </div>
                      <div class="block sm:grid grid-cols-5 text-sm">
                        <p class="col-span-1" width="120px">
                          {{ $t("integration.webhook_event.created_at") }}:
                        </p>
                        <p class="col-span-4">
                          {{
                              $moment(webhook_event_log.created_at).format(
                                "DD MMM YYYY hh:mm a"
                              )
                          }}
                        </p>
                      </div>

                      <!-- Payload -->
                      <app-expand v-if="!$lodash.isEmpty(webhook_event_log.payload)">
                        <template v-slot:head="slot">
                          <div class="flex justify-between">
                            <div class="flex space-x-2 cursor-pointer">
                              <p>
                                {{ $t("integration.webhook_event.payload") }}
                              </p>
                              <app-icon name="ChevronRightIcon" :class="[
                                'h-5 w-5 text-gray-500 transform',
                                slot.expanded ? '-rotate-90' : 'rotate-90',
                              ]" />
                            </div>
                            <app-button-copy v-if="slot.expanded" :title="$t('general.copy')" :textToCopy="
                              JSON.stringify(webhook_event_log.payload)
                            " @click.stop />
                          </div>
                        </template>
                        <template v-slot:body>
                          <div class="
                              block
                              whitespace-pre
                              overflow-x-scroll
                              bg-gray-900
                              text-yellow-200 text-xs
                              p-2
                              rounded-md
                              my-3
                            ">
                            <code v-text="webhook_event_log.payload" />
                          </div>
                        </template>
                      </app-expand>

                      <!-- Exception -->
                      <app-expand v-if="!$lodash.isEmpty(webhook_event_log.exception)">
                        <template v-slot:head="slot">
                          <div class="flex justify-between">
                            <div class="flex space-x-2 cursor-pointer">
                              <p>
                                {{ $t("integration.webhook_event.exception") }}
                              </p>
                              <app-icon name="ChevronRightIcon" :class="[
                                'h-5 w-5 text-gray-500 transform',
                                slot.expanded ? '-rotate-90' : 'rotate-90',
                              ]" />
                            </div>
                            <app-button-copy v-if="slot.expanded" :textToCopy="
                              JSON.stringify(webhook_event_log.exception)
                            " :title="$t('general.copy')" @click.stop />
                          </div>
                        </template>
                        <template v-slot:body>
                          <div class="
                              block
                              whitespace-pre
                              overflow-x-scroll
                              bg-red-900
                              text-white text-xs
                              p-2
                              rounded-md
                              my-3
                            ">
                            <code v-text="webhook_event_log.exception" />
                          </div>
                        </template>
                      </app-expand>
                    </div>
                  </div>
                </div>
              </template>
            </app-expand>
          </div>
        </div>
      </div>
    </template>
  </app-full-modal>
</template>

<script>
export default {
  data() {
    return {
      showModalDetail: false,
      selectWebhookEventLog: null
    };
  },
  props: {
    payment_link: {
      type: Object,
      default: null,
    },
    resendEmailButton: {
      type: Boolean,
      default: true,
    },
    showBusinessDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    loading() {
      return this.$store.getters["paymentStore/loading"];
    },

    message() {
      return this.$store.getters["paymentStore/message"];
    },

    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"]
    },
  },
  methods: {
    reverseAttempt() {
      let attempts = this.payment_link?.attempts;
      return attempts?.reverse();
    },
    onClick(value) {
      this.$emit("onClick", value);
    },
    getPaymentAttempt(paymentAttemptId) {
      return this.payment_link.attempts.find(
        (attempt) => attempt._id === paymentAttemptId
      );
    },
    async fetchData(payment_link_id) {
      let response = await this.$store.dispatch(
        "paymentStore/getPaymentLink",
        {
          "business_id" : this.payment_link?.business?.id,
          "payment_link_id" : payment_link_id
        }
      );
      this.$emit("update:payment_link", response.data);
    },
    async requery(attempt_id) {
      let requery = await this.$store.dispatch("paymentStore/requery", {
        payment_link: this.payment_link,
        attempt_id: attempt_id,
      });

      if (requery == null) {
        this.$notify(
          {
            group: "error",
            title: this.message,
          },
          5000
        );
        return;
      }

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.requery", {
            attempt_id: attempt_id,
          }),
        },
        5000
      );
    },

    getPaymentAmount(payments) {
      if (payments == null)
        return null;

      var paymentAmount = {
        net_amount: null,
        amount: null,
        charges: null
      };

      payments.forEach((payment) => {
        paymentAmount.net_amount += payment.settlement_amount;
        paymentAmount.amount += payment.amount;
        paymentAmount.charges += (payment.amount - payment.settlement_amount)
      });

      return paymentAmount;
    },

    async resendEmail(model) {
      const resendEmail = await this.$store.dispatch(
        "paymentStore/resendEmail",
        model._id
      );

      if (resendEmail == null) {
        this.$notify(
          {
            group: "error",
            title: this.message,
          },
          5000
        );
        return;
      }

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.resend"),
        },
        5000
      );
    },
    onItemClick(webhookEventLog) {
      this.showModalDetail = !this.showModalDetail;
      this.selectWebhookEventLog = webhookEventLog;
    },
  },
};
</script>
